import React, { useState } from 'react';
import styled from 'styled-components';
import QRCode from 'qrcode.react';

const Home = () => {
  const [qrValue, setQrValue] = useState('');

  return (
    <Page>
      <Header>QR Creator</Header>
      <InputField>
        <label htmlFor="qrValue">QR Value:</label>
        <TextInput
          type="text"
          name="qrValue"
          value={qrValue}
          onChange={({ target }) => setQrValue(target.value)}
        />
      </InputField>
      <QRContainer>{qrValue ? <QRCode value={qrValue} /> : null}</QRContainer>
      <Footer>To download: right-click and select Save Image As...</Footer>
    </Page>
  );
};

const Page = styled.div`
  width: 90%;
  max-width: 900px;
  margin: 0 auto;
`;

const Header = styled.h2`
  font-size: 2rem;
`;

const InputField = styled.div`
  font-size: 1.5rem;
`;

const TextInput = styled.input`
  font-size: 1.15rem;
  width: 100%;
  margin: 5px;
  padding: 2px;
`;

const QRContainer = styled.div`
  margin-top: 30px;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Footer = styled.h4`
  text-align: center;
  margin-top: 10px;
`;

export default Home;
